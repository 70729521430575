import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';
import tw from 'tailwind.macro';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';
import Button from '../components/button';
import Grid from '../components/grid';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Panel from '../components/panel';
import Section from '../components/section';
import SEO from '../components/seo';
import SafeLink from '../components/safeLink.js';

const fullConfig = resolveConfig(tailwindConfig);

const Index = ({ data, location }) => {
  const parentTitle = '';
  const pageTitle = 'Home';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <div css={tw`bg-maroon`}>
        <BackgroundImage
          fluid={data.homeHero.childImageSharp.fluid}
          className="container"
          css={[tw`relative bg-maroon bg-right`, `background-size: auto 140%;`]}
        >
          <div css={[tw`flex flex-col`, `min-height:70vh`]}>
            <div css={tw`pt-10 pb-5 md:py-0 my-auto`}>
                <div className="lg:w-2/3">
                  <h1
                    className="text-shadow-lg"
                    css={[
                      tw`mb-5 text-white text-left tracking-wider underline`,
                      `text-decoration-color: ${fullConfig.theme.colors.gold.default}`,
                    ]}
                  >
                    The 5th International Social Justice Conference and 6th Annual Social Justice Summit
                  </h1>
                  <LeadText css={tw`text-white`}>16 – 18 October 2024</LeadText>
                  <Button to="/conference-2024/" variant="outline" size="lg">
                    &larr; The Conference
                  </Button>&nbsp;
                  <Button to="/summit-2024/" variant="outline" size="lg">
                    The Summit &rarr;
                  </Button>
                  <p><SafeLink to="/blog/2024/08/social-justice-champion-awards-2024" css={tw`text-white border-b border-white`}>Nominations now open for the Social Justice Champion Awards!</SafeLink></p>
                </div>
                {/* <SafeLink to="/conference-summit-2023/">
                  <Img
                    fluid={data.summit2023Banner.childImageSharp.fluid}
                    alt="The 4th International Conference on Social Justice and the 5th Social Justice Summit, 11-12 October 2023"
                    css={tw`w-full`}
                  />
                </SafeLink> */}
                 
            </div>
            <div
              css={tw`-mb-16 px-36 py-5 text-center rounded-lg border-t-8 border-gold bg-white shadow-lg`}
            >
              <h2
                css={tw`px-5 md:px-0 text-xl uppercase tracking-wider font-normal`}
              >
                A globally respected social justice research and training hub
              </h2>
            </div>
          </div>
        </BackgroundImage>
      </div>
      <Section isThin isShaded>
        <div css={tw`pt-20 pb-10`}>
          <Grid>
            {data.latestEvents.edges.map(({ node }) => {
              return (
                <Panel css={tw`flex flex-col items-start`}>
                  <SafeLink to={node.frontmatter.permalink}>
                    <h3>{node.frontmatter.title}</h3>
                    <p css={tw`mt-auto mb-0`}>{node.frontmatter.eventDate}</p>
                  </SafeLink>
                </Panel>
              );
            })}
          </Grid>
        </div>
      </Section>
      <Section isThin>
        <div css={tw`pt-10 pb-10`}>
          <Grid lgGridCols={2} lgGap={20}>
            <div css={tw`flex items-center`}>
              <Img
                fluid={data.profMadonsela.childImageSharp.fluid}
                alt="Prof. Thuli Madonsela"
                css={tw`rounded-lg w-full`}
              />
            </div>
            <div css={tw`flex items-center`}>
              <div>
                <h2>
                  Prof. Thuli Madonsela <br />
                  <small css={tw`text-dark-gray font-normal`}>
                    Director of the Centre for Social Justice, Stellenbosch University
                  </small>
                </h2>
                <p>Professor Thuli (Thulisile) Madonsela is a professor of law occupying the Law Trust Research Chair in Social Justice at Stellenbosch University. She is also a member of the African Academy of Sciences and founder of the Thuma Foundation for Democracy Leadership and Literacy a Member of the African Academy of Sciences, a 2017 Harvard Advanced Leadership Fellow, and a One Young World Counsellor. The former Public Protector of South Africa and full-time Law Commissioner, Professor Madonsela was one of the drafters of the South African Constitution and a co-architect and drafter of several constitutionally mandated laws, including the Equality Act, Employment Equity Act and Promotion of Administrative Justice Act.</p>
                <Button to="/about/">Read more &rarr;</Button>
              </div>
            </div>
          </Grid>
        </div>
      </Section>
      <Section isShaded>        
        {/*<Img
          fluid={data.conferenceSummit2024Banner.childImageSharp.fluid}
          alt="The 5th International Conference on Social Justice and the 6th Social Justice Summit"
          css={tw`mb-5 w-full`}
        />
        <Button to="/conference-2024/" size="md">
          Learn more about the Conference &rarr;
        </Button>&nbsp;
        <Button to="/conference-2024/" size="md">
          Learn more about the Summit &rarr;
        </Button>        
        <hr css={tw`mt-5 mb-10`} />*/}
        <h2>Missed the previous summits and conferences?</h2>
        <Button to="/summit-2020/" size="md">
          View 2020 page &rarr;
        </Button> <Button to="/summit-conference-2021/" size="md">
          View 2021 page &rarr;
        </Button> <Button to="/conference-summit-2022/" size="md">
          View 2022 page &rarr;
        </Button> <Button to="/conference-summit-2023/" size="md">
          View 2023 page &rarr;
        </Button>
      </Section>
      <BackgroundImage
        fluid={[
          `linear-gradient(${fullConfig.theme.colors.maroon.translucent} 15%,transparent)`,
          data.activities.childImageSharp.fluid,
        ]}
        css={tw`relative pt-20 pb-32`}
      >
        <div className="container" css={tw`mb-10`}>
          <h2 className="text-shadow-lg" css={tw`mt-0 text-white`}>
            Featured projects
          </h2>
          <LeadText className="text-shadow" css={tw`text-white`}>
            The Centre for Social Justice is anchored in the triple
            objectives of responsive research, teaching and social impact. The
            Social Justice M-Plan is the flagship programme of Law Trust the
            Chair in Social Justice.
          </LeadText>
        </div>
        <div className="container">
          <Grid lgGridCols={2}>            
            <Panel css={tw`flex flex-col content-start items-start p-10`}>
              <h3 css={tw`mt-0`}>The Musa Plan for Social Justice (M-Plan)</h3>
              <LeadText>
                A social justice accelerator programme. Its theory of change is
                that poverty and inequality are systems problems and require a
                systems approach to change.
              </LeadText>
              <Button to="/projects/m-plan/" css={tw`mt-auto`}>
                Explore &rarr;
              </Button>
            </Panel>
            <Panel css={tw`flex flex-col content-start items-start p-10`}>
              <h3 css={tw`mt-0`}>#ACTION4INCLUSION</h3>
              <LeadText>
                A campaign to address the plight of historic debt owed to
                universities by students from poor and working-class families.
              </LeadText>
              <Button to="/projects/action4inclusion/" css={tw`mt-auto`}>
                Explore &rarr;
              </Button>
            </Panel>
            <Panel css={tw`flex flex-col content-start items-start p-10`}>
              <h3 css={tw`mt-0`}>
                Social Justice and COVID-19 Policy and Relief Monitoring
                Alliance (SCOPRA)
              </h3>
              <LeadText>
                A project to regularly track all COVID-19 policies and relief
                measures to assess and ensure compliance with the equality duty
                and related social justice commitments, other human rights
                obligations and democratic governance dictates.
              </LeadText>
              <Button to="/projects/scopra/" css={tw`mt-auto`}>
                Explore &rarr;
              </Button>
            </Panel>
            <Panel css={tw`flex flex-col content-start items-start p-10`}>
              <h3 css={tw`mt-0`}>Social Justice Café</h3>
              <LeadText>
                The aim of the cafés is to engage with young people on social
                justice issues and human rights-inspired democracy and action
                for inclusion.
              </LeadText>
              <Button to="/projects/social-justice-cafe/" css={tw`mt-auto`}>
                Explore &rarr;
              </Button>
            </Panel>            
          </Grid>
        </div>
      </BackgroundImage>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    homeHero: file(relativePath: { eq: "home-hero1.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    profMadonsela: file(relativePath: { eq: "thuli-madonsela-2022.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    activities: file(relativePath: { eq: "home-activities.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    action4inclusionHero: file(
      relativePath: { eq: "projects-action4inclusion-banner.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    conferenceSummit2024Banner: file(relativePath: { eq: "2024-06-save-the-date-2024-international-social-justice-conference-and-social-justice-summit.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }      
    latestEvents: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "event" } } }
      sort: { fields: [frontmatter___eventDate], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            author
            date(formatString: "D MMMM YYYY")
            permalink
            title
            topics
            eventDate(formatString: "D MMMM YYYY")
          }
        }
      }
    }
  }
`;
